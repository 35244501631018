var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingDelete },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.deleteReward },
      }),
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: { color: "primary" },
                          on: { click: _vm.closeDetail },
                        },
                        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-toolbar",
                        { staticClass: "secondary white--text " },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "font-weigh-bold text-h5" },
                            [_vm._v(" Claim Reward ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3 ma-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: "Name",
                                },
                                model: {
                                  value: _vm.reward.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reward, "name", $$v)
                                  },
                                  expression: "reward.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  outlined: "",
                                  label: "Description",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.reward.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reward, "description", $$v)
                                  },
                                  expression: "reward.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-select", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  items: _vm.typeOptions,
                                  label: "Type",
                                },
                                model: {
                                  value: _vm.reward.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reward, "type", $$v)
                                  },
                                  expression: "reward.type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column justify-start" },
                            [
                              _c("v-text-field", {
                                staticStyle: {
                                  "margin-bottom": "-10px",
                                  "margin-top": "10px",
                                },
                                attrs: {
                                  disabled: _vm.reward.pre_order,
                                  type: "number",
                                  dense: "",
                                  outlined: "",
                                  label: "Quantity",
                                },
                                model: {
                                  value: _vm.reward.quantity,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reward, "quantity", $$v)
                                  },
                                  expression: "reward.quantity",
                                },
                              }),
                              _c("v-checkbox", {
                                staticStyle: {
                                  "margin-top": "-10px",
                                  "margin-bottom": "10px",
                                },
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: "Pre-Order",
                                },
                                model: {
                                  value: _vm.reward.pre_order,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reward, "pre_order", $$v)
                                  },
                                  expression: "reward.pre_order",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  dense: "",
                                  outlined: "",
                                  label: "Required Points",
                                },
                                model: {
                                  value: _vm.reward.required_points,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reward, "required_points", $$v)
                                  },
                                  expression: "reward.required_points",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "number",
                                  dense: "",
                                  outlined: "",
                                  label: "priority",
                                  min: "10",
                                },
                                model: {
                                  value: _vm.reward.order,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reward, "order", $$v)
                                  },
                                  expression: "reward.order",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-start" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "mdi-calendar",
                                          dense: "",
                                          outlined: "",
                                          label: "Start Date",
                                        },
                                        on: {
                                          "click:prepend":
                                            _vm.redirectStartDate,
                                          click: _vm.redirectStartDate,
                                        },
                                        model: {
                                          value: _vm.reward.start_at,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reward,
                                              "start_at",
                                              $$v
                                            )
                                          },
                                          expression: "reward.start_at",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: { height: "auto", width: "auto" },
                                      model: {
                                        value: _vm.isStartDate,
                                        callback: function ($$v) {
                                          _vm.isStartDate = $$v
                                        },
                                        expression: "isStartDate",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex justify-center",
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "pa-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(" Start Date "),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          flat: "",
                                                          landscape: true,
                                                          reactive: false,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.reward.start_at,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.reward,
                                                              "start_at",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "reward.start_at",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._t("footer", function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-end my-3",
                                                          attrs: {
                                                            color: "white",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                plain: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function () {
                                                                    _vm.isStartDate = false
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.confirmStartDate,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Confirm "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  }),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-col", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "prepend-icon": "mdi-calendar",
                                        dense: "",
                                        outlined: "",
                                        label: "End Date",
                                      },
                                      on: {
                                        "click:prepend": _vm.redirectEndDate,
                                        click: _vm.redirectEndDate,
                                      },
                                      model: {
                                        value: _vm.reward.end_at,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.reward, "end_at", $$v)
                                        },
                                        expression: "reward.end_at",
                                      },
                                    }),
                                    _c(
                                      "v-dialog",
                                      {
                                        attrs: {
                                          height: "auto",
                                          width: "auto",
                                        },
                                        model: {
                                          value: _vm.isEndDate,
                                          callback: function ($$v) {
                                            _vm.isEndDate = $$v
                                          },
                                          expression: "isEndDate",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-center",
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "pa-3" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(" End Date "),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("v-date-picker", {
                                                          attrs: {
                                                            flat: "",
                                                            landscape: true,
                                                            reactive: false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.reward.end_at,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.reward,
                                                                "end_at",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "reward.end_at",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._t(
                                                      "footer",
                                                      function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-end my-3",
                                                              attrs: {
                                                                color: "white",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function () {
                                                                        _vm.isEndDate = false
                                                                        _vm.reward.end_at =
                                                                          null
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Cancel "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.confirmEndDate,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Confirm "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-file-input", {
                                attrs: {
                                  rules: _vm.rulesImage,
                                  accept: "image/png",
                                  color: "primary",
                                  outlined: "",
                                  mutliple: "",
                                  label: "Upload the new reward image",
                                },
                                model: {
                                  value: _vm.attachment,
                                  callback: function ($$v) {
                                    _vm.attachment = $$v
                                  },
                                  expression: "attachment",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end pt-4" },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", plain: "" },
                                      on: {
                                        click: function () {
                                          _vm.$router.go(-1)
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.api.isLoading,
                                      },
                                      on: { click: _vm.validateInput },
                                    },
                                    [_vm._v(" Confirm ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }